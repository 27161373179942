@mixin coluna-flex($spacing) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: $spacing;
}

@mixin linha-flex($spacing) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: $spacing; 
}

@mixin caixa-de-sombra {
    box-shadow: 0px 4px 8px rgba($cor-preta,0.15);
}

@mixin dgx-card__texto {
	font-family: $family-sans-serif;
	font-size: 14px;
	line-height: 1.25em;
}

// Mixin para declarar uma cor
@mixin definirCorHSL($color, $hue, $saturation, $lightness) {
    #{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");
    #{$color}-h: #{$hue};
    #{$color}-s: #{$saturation};
    #{$color}-l: #{$lightness};
}