.passos{
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color:#BDBDBD;
    margin-bottom: 30px;

}

.icones {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    position: relative;
    i {
        font-size: 18px;
        z-index: 1; 
        background-color: white;
        color:#BDBDBD;
    }
    i.preto {
        color:#424242;
    }
}

.icones::before {
    content: "";
    position: absolute;
    top: 40%;
    left: 1px;
    right: 1px;
    border-top: 2px dashed #9E9E9E;
    z-index: 0;
}

.numero-inscricao {
    display: flex;
    justify-content: center;
    font-size: 25px;
    margin-top: 30px;
    margin-bottom: 30px;
  }