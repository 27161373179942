
.video-container {
    display: flex;
    justify-content: center;    
}
    .container_bordas_redondas {
        width: 100%;
    }

.img-minha-casa-minha-vida {
    position: relative;
    width: 80px;
    top: -5px;
    right: 15px;
}

.img-fgts {
    position: relative;
    width: 80px;
}