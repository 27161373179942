.formulario__radio-container {
    align-items: flex-start;

    input {
        margin-top: 1px !important;
    }

    .formulario__descricao {
        margin-bottom: 0;
    }
}