.container-separacao-inscricao {
  margin-top: 90px;
}

.numero-inscricao {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}