.menu-de-inscricao {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 120px);
    @media screen and (min-width: 1024px) {
        position: relative;
        top: 114px;
    }

    .menu-de-inscricao__container {
        max-width: 1080px;
        width: 100%;
        margin: auto;
    }
}

.menu-de-inscricao__container {
    .container__conteudo {
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        justify-content: space-between;
        @media screen and (max-width: 748px) {
            flex-direction: column;
            .menu-de-inscricao__item {
                width: 100%;
            }
        }
    }
}

.menu-de-inscricao__titulo {
    padding: 0 16px;
    font-size: 18px;
    color: $cor-cinza-80;
    @include fonte-seminegrito;
    margin-top: 24px;
    @media screen and (max-width: 600px) {
        margin-top: 0;
    }
}

.menu-de-inscricao__item {
    width: calc(50% - 4px);
    display: flex;
    background-color: $cor-branca;
    @include borda-arredondada-padrao;
    padding: 16px;
    font-family: $fonte-padrao;
    color: $cor-cinza-80;
    text-align: left;
    margin-bottom: 8px;
    transition: all cubic-bezier(0.89, 0.55, 0.38, 1.03) .2s;
    justify-content: space-between;
    border: 2px solid $cor-branca;
    
    &:hover, &:focus {
        transform: translate(0px, -2px);
        @include sombra-padrao(baixo);
        outline: 0;

        .menu-de-inscricao__numero-da-categoria {
            box-shadow: 0px 0px 0px 4px hsla(var(--cor-secundaria-h), var(--cor-secundaria-s), var(--cor-secundaria-l), 0.4);
        }
    }
    @media screen and (max-width: 420px) {
        justify-content: center;
    }
}

.menu-de-inscricao__item_destaque {
    width: 100%;
    margin-top: 8px;
    border: 2px solid  hsla(var(--cor-secundaria-h), var(--cor-secundaria-s), var(--cor-secundaria-l), 0.5);
    &:hover, &:focus {
        border-color: var(--cor-secundaria);
    }
}

.menu-de-inscricao__item_desativado {
    display: none;
    &.menu-de-inscricao__item_destaque {
        display: flex;
        border-color: $cor-cinza-40;
        &:hover, &:focus {
            cursor: not-allowed;
            transform: none;
            box-shadow: none;
        }
        &:focus {
            border-color: $cor-cinza-60;
        }
        .menu-de-inscricao__numero-da-categoria {
            background-color: $cor-cinza-40;
            box-shadow: none;
        }
        .menu-de-inscricao__descricao-do-conteudo {
            color: $cor-cinza-70;
        }
        .menu-de-inscricao__titulo-do-conteudo {
            color: $cor-cinza-60;
        }

    }
}

.menu-de-inscricao__numero-da-categoria {
    box-shadow: 0px 0px 0px 4px hsla(var(--cor-secundaria-h), var(--cor-secundaria-s), var(--cor-secundaria-l), 0.2);
    position: relative;
    color: $cor-branca;
    min-width: 40px;
    font-size: 18px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--cor-secundaria);
    @include fonte-seminegrito;
    margin-right: 12px;
    transition: box-shadow .2s ease;

    @media screen and (max-width: 420px) {
        margin: 0;
        margin-bottom: 8px;
    }
}

.menu-de-inscricao__conteudo {
    display: flex;
    align-items: center;
    @media screen and (max-width: 420px) {
        flex-direction: column;
        align-items: center;
    }
}

.menu-de-inscricao__texto-do-conteudo {
    margin-right: 8px;
    @media screen and (max-width: 420px) {
        text-align: center;
        margin-right: 0px;
    }
}

.menu-de-inscricao__descricao-do-conteudo {
    font-size: 14px;
    color: $cor-cinza-70;
}

.menu-de-inscricao__titulo-do-conteudo {
    font-size: 16px;
    color: var(--cor-secundaria);
    align-self: center;
}

.menu-de-inscricao__icone-direita {
    display: flex;
    align-items: center;
    @media screen and (max-width: 420px) {
        display: none;
    }
}

.menu-de-inscricao__icone {
    opacity: 0;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    background-color: var(--cor-primaria);
    position: absolute;
    bottom: -8px;
    right: -8px;
    color: $cor-branca;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $cor-branca;
    
    span {
        border: 2px solid $cor-branca;
        border-top: 0;
        border-left: 0;
        width: 5px;
        height: 9px;
        position: absolute;
        top: 3px;
        left: 6px;
        transform: rotate(45deg)
    }
}

.menu-de-inscricao__item_preenchido {
    .menu-de-inscricao__icone {
        opacity: 1;
    }
    .menu-de-inscricao__numero-da-categoria {
        box-shadow: 0px 0px 0px 4px hsla(var(--cor-primaria-h), var(--cor-primaria-s), var(--cor-primaria-l), 0.2);
        background-color: var(--cor-primaria);
    }
}