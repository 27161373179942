.logo-governo {
    width: 256px;
    margin: 0 auto;

    .seinfra {
        fill: #27963C;
    }
}

.logo-governo_branco {
    .seinfra {
        fill: #FFF;
    }
}
