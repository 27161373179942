.container {
  @include borda-arredondada-padrao;
}

.container_pequeno {
  max-width: 912px;
}

.container_mini {
  max-width: 612px;
}