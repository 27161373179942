.titulo-juncao-inscricao {
    display: flex;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 20px;
}

.item-juncao-inscricao {
    display: flex;
}

.margen-inferior {
    display: flex;
    margin-bottom: 15px;
}

.pergunta-juncao-inscricao {
    display: flex;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 18px;
}