* {
  margin: 0;
  padding: 0;
}

html {
  overflow: auto;
}

.pagina-de-entrada__container_primario {
  background-image: none;
}

a {
  color: $cor-info;
  cursor: pointer;
  text-decoration: none;
}

strong {
  color: currentColor !important;
}

hr {
  margin: 8px 0;
  border: 0;
  height: 2px;
  background-color: $cor-cinza-20;
}

.pagina-container {
  @include coluna-flex(center);
  .button {
    width: 100%;
  }

  .title {
    color: $cor-cinza-80;
    margin-bottom: $espacamento-duplo;
  }
  
  .field-top {
    display: flex;
    justify-content: space-between;

    .field-link {
        color: var(--cor-secundaria);
        font-weight: 700;
        font-size: 0.875rem;
        text-decoration: underline;
    }
  }
}

.lista-de-cadastro__item {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  margin-top: 8px;
  justify-content: space-between;
  padding: 16px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  flex-direction: column;
}

.lista-de-cadastro__botao {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  padding: 24px;
  height: auto;
  margin-bottom: 16px;
  white-space: inherit;

  svg {
    font-size: 32px;
    margin-bottom: 8px;
  }
}

.lista-de-cadastro__info {
  display: flex;
  margin-bottom: 8px;
  text-transform: uppercase;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.lista-de-cadastro__tags {
  margin-bottom: 12px;
  @media screen and (max-width: 600px) {
    text-align: center;
  }
}

.lista-de-cadastro__tag {
  font-size: 13px;
  @include fonte-seminegrito;
  background: $cor-cinza-10;
  padding: 4px 16px;
  color: $cor-cinza-70;
  display: inline-block;
  border-radius: 24px;
  margin: 4px 2px;
}

.lista-de-cadastro__dados {
  align-self: center;
  @media screen and (max-width: 600px) {
    text-align: center;
  }
}

.lista-de-cadastro__icone {
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 32px;
  color: var(--cor-secundaria);
  height: 100%;
  align-self: center;
  margin-right: 8px;
}

.lista-de-cadastro__titulo {
  font-size: 18px;
  @include fonte-seminegrito;
  color: var(--cor-secundaria);
  text-transform: uppercase;
}

.lista-de-cadastro__subtitulo {
  color: $cor-cinza-70;
  font-size: 14px;
}

.lista-de-cadastro__acoes {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .botao:first-child {
    margin-right: 16px;
  }
    @media screen and (max-width: 600px) {
      flex-direction: column;
      .botao:first-child {
        margin: 8px 0;
    }
  }
}

// DigixUI 

.formulario__input-radio {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.formulario__input-radio_desabilitado {
  cursor: not-allowed;
  .input-marcador {
    &:after {
      background-color: $cor-cinza-50; 
    }
  } 
}

.formulario__descricao {
  font-size: 14px;
  margin-bottom: 8px;
  @include fonte-regular;
}

.botao_texto-branco {
  &:hover {
    color: $cor-branca;
  }
}

.grade__linha.grade_sem-margem {
  padding: 0;
}

.grade__coluna.grade_sem-margem {
  padding: 0 0.5em;
  .formulario__label {
    margin: 0;
  }
}

.formulario__label {
  p.texto_pequeno {
    @include fonte-regular;
  }
}

.barra-navegacao {
  @media screen and (max-width: 600px) {
    margin-bottom: 24px;
  }
}

.pagina {
  min-height: 100vh;
  @media screen and (max-width: 600px){
    overflow-x: hidden;
  }
}

input[type="radio"]:focus + label {
  border-color: $cor-info !important;
}

input[type="checkbox"]:focus + label {
  border-color: $cor-info !important;
}

.Select-input {
  input {
    padding: 0;
    max-width: 260px !important;
    text-overflow: ellipsis !important;
    border: 0;
    position: relative;
    background: 0;
    top: 8px;
  }
}

.botao-abrir-menu-administrativo {
  cursor: default !important;
  &:hover, &:focus {
    background: $cor-terciaria !important;
  }
  @media screen and (max-width: 360px) {
    display: none !important;
  }
}

.pagina-de-entrada .formulario input {
  margin-bottom: 0px;
}

input {
  text-transform: uppercase;
}

input[type="email"]{
  text-transform: lowercase;
}

.barra-navegacao_fixa {
  @media screen and (min-width: 1024px) {
    position: fixed !important;
    top: 48px !important;
    z-index: 3;
  }
}

.cabecalho-sistema__nome-do-usuario {
  color: $cor-branca;
}

.cabecalho-sistema_fixo-ao-topo {
  @media screen and (min-width: 1024px) {
    position: fixed !important;
    top: 0px !important;
  }
}

.container_com-cabecalho-fixo {
  @media screen and (min-width: 1024px) {
    margin-top: 136px !important;
  }
}

.container {
  @media screen and (max-width: 720px) {
    padding: 0;
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
}

.u-texto-nao-quebrar {
  white-space: nowrap !important;
}

.u-largura-total {
  width: 100% !important;
}

.cartao__cabecalho {
  justify-content: center;
}

#foco-inicial-do-teclado {
  outline: none;
}

.u-lista-sem-marcadores {
  list-style: none;
}

.cartao_sem-estilo {
  border: 0;
  padding: 0;

  .cartao__cabecalho {
    padding: 0;
  }
  
  .cartao__corpo {
    margin-top: 16px;
    border: 0;
    padding: 0;
  }
}

.u-margem-direita-negativa-pequena {
    margin-right: -10px;
}

.u-alinhamento-vertical-superior {
  align-self: start;
}

.u-cor-cinza-sombreada {
  background-color: rgba(185, 182, 182, 0.9);
  &:hover {
    background-color: rgba(168, 165, 165, 0.9);
  }
  &:focus {
    background-color: rgba(185, 182, 182, 0.9);
  }
}

.cabecalho-sistema_fixo {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}
.cartao-alinhamento-inferior{
  margin-bottom: 15px;
}
.alinhamento-superior{
  margin-top: 20px;
}