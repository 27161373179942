@media print { 
    .pagina-comprovante {
        padding: 0 !important;
        .cabecalho-sistema,
        .barra-navegacao,
        .container__botao-imprimir {
            display: none !important;
        }
        .pagina-comprovante__container {
            padding: 0 !important;
        }
        background-color: $cor-branca !important; 
        min-height: 100% !important;
    }

    .pagina {
        min-height: 100%;
    }

    #librasgovbr{
        display: none;
    }
}

.pagina-comprovante {
    color: $cor-preta !important;
    background-color: $cor-cinza-30; 
    padding: 0;
    box-sizing: border-box !important;
    width: inherit;
    min-height: 100vh;
    height: 100%;
    @media screen and (min-width: 1024px) { 
        padding-top: 114px;
    }

    .container__botao-imprimir {
        display: flex;
        width: 100%;
        margin: 16px 0;
        .botao {
            margin: auto;
        }
    }
    
    .pagina-comprovante__container {
        margin: 0 auto;
        margin-bottom: 24px;
        max-width: 800px;
        padding: 32px;
        background-color: $cor-branca;
        @media screen and (max-width: 650px){
            width: 100%;
            padding: 16px;
        }
    }

    .comprovante__topo {
        width: 100%;
        margin: 0 auto;

        .comprovante-topo__logos {
            display: flex;
            padding-bottom: 15px;
            img {
                width: 100%;
                height: 70px;
            }
        }
        
        .comprovante-topo__cabecalho {
            display: flex;
            justify-content: space-between;
            align-content: center;
            align-items: center;
            padding-bottom: 8px;
    
            span {
                text-align: right;
            }
        }
    }
    
    .comprovante-cabecalho__titulo {
        text-align: left;
        font-size: 22px;
        font-weight: bold;
        line-height: 1em;

        @media screen and (max-width: 480px){
            font-size: 16px;
        }
    }
    .comprovante-inscricao__enunciado {
        font-weight: bold;
    }
    
    .comprovante-inscricao__numero {
        @media screen and (max-width: 480px){
            font-size: 16px;
        }
    }
    
    .comprovante__secao {
        border: 1px solid $cor-cinza-30;
        border-radius: 10px;
        padding: 7px;
        margin-bottom: 10px;
        font-size: 15px;
        .titulo {
            font-size: 15px;
            font-weight: bold;
            margin-top: 0px;
            margin-bottom: 10px;
        }
        div {
            text-align: left;
        }
        span{
            font-weight: bold;
            display: inline;
            text-align: left;
            margin-top: 5px;
        }
    }

    .comprovante-secao__titulo {
        font-size: 11px;
        font-weight: bold;
        margin-bottom: 8px;
    }

    .comprovante-secao__nome-e-cpf {
        font-size: 16px;
        display: flex;
        justify-content: space-between;   
    }

    .comprovante-secao__nome {
        font-weight: bold;
        flex-basis: 540px;
        line-height: 1.2;
        padding-right: 12px;
    }

    .comprovante-secao__cpf {
        flex-grow: 1;
    }

    .comprovante__linha.dados-adicionais {
        font-size: 12px;
    }
    
    .comprovante__lista-nao-ordenada {
        margin-left: 16px;
        list-style-type: disc;
        
        li {
            line-height: 1.2em;
            font-size: 11px;
        }
    }
    
    .comprovante__informacoes {
        line-height: 1.5;
        font-size: 15px;
        padding-bottom: 16px;
        margin-bottom: 16px;
    
        .comprovante-informacoes__titulo {
            margin-bottom: 8px;
            font-weight: bold;
        }
        
        .comprovante-informacoes__lista-ordenada {
            list-style-position: inside;
        }
        p{
            margin-bottom: 5px;
        }
    }
    
    .comprovante__linha {
        display: flex;
    }

    .comprovante-linha__coluna {
        flex: 1;
    }
}

.comprovante__linha.dados-adicionais {
    .comprovante-linha__coluna {
        flex: 0 180px;
    }
}

.comprovante-secao__sobre-a-familia {
    margin-left: 16px;
}

.comprovante-secao__endereco {
    padding-right: 16px;
    p {
        font-size: 12px;
    }
}

p.comprovante-secao__cidade {
    font-size: 14px;
    font-weight: bold;
}

.comprovante-rodape__titulo {
    font-size: 11px;
    font-weight: bold;
}

.comprovante-rodape__informacao {
    font-size: 15px;
}

.comprovante-secao__municipio {
    justify-content: space-between !important;

}

.comprovante-secao__pretendente.secao__conjuge {
    margin-left: 7px;
}

.comprovante-secao__atendimento{
    .item{
        margin-bottom: 5px;
    }
}