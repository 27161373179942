.container__rodape {
  padding: 16px;
  border-top: 2px solid $cor-cinza-20;
}

.container__rodape_com-botoes-distribuidos {
  display: flex;
  flex-direction: row;

  &>* {
      margin-left: 16px;
      flex-grow: 1;
  }

  &> :first-child {
      margin-left: 0 !important;
  }
}