input::placeholder {
  text-transform: none;
}

.acoes {
  position: relative;
  display: inline-block;
}

.inscricao-acoes__lista {
  position: absolute;
  top: calc(100% + 4px);
  right: 0;
  z-index: 100;
  margin: 0;
  padding: 2px;
  width: 260px;
  border-radius: 4px;
  background: #c0c0c0;
  list-style-type: none;

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;

    position: absolute;
    top: -4px;
    right: 18px;

    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #c0c0c0;
  }
}

.inscricao-acoes__item {
  border-bottom: 1px solid #c0c0c0;
  cursor: pointer;

  &:first-of-type {
    .protocolo__botao {
      border-radius: 4px 4px 0 0;
    }
  }

  &:last-of-type {
    border-bottom: 0;

    .protocolo__botao {
      border-radius: 0 0 4px 4px;
    }
  }
}

.inscricao-acoes__botao {
  display: flex;
  align-items: center;
  width: 100%;
  color: #222;
  text-align: left;
  min-height: 50px;
  background: #fff;
  transition: background .2s ease;
  border: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 14.4px;
  text-decoration: none solid rgb(33, 33, 33);
  word-spacing: 0px;
  font-weight: 600;
  cursor: pointer;

  &:hover,
  &:focus {
    background: #eee;
  }

  &:disabled {
    cursor: default;
    color: #999;
    opacity: 1;

    &:hover,
    &:focus {
      background: #fff;
    }

    .fa-home {
      color: #999;
    }
  }
}

.inscricao-acoes__botao-expansivel {
  font-weight: 700;
}

.inscricao-acoes__icone {
  margin: 2px 8px 0 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  margin-left: 20px;
  margin-top: 10px;
}

.sumario-paginacao {
  position:relative;
  bottom: 36px;
  left: 6px;
  font-size: 21.4px;
  font-weight: 500;
}

.informacao_tooltip {
	color: #e38e02;
  left: 5px;
}