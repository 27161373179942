.pagina-de-entrada {
  background-image: url(../../../imagens/governo-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;

  .titulo {
    line-height: 1.3;
  }

  .formulario__mensagem {
    margin: 8px 0 0;
  }

  .botao_sem-altura {
    height: auto;
    min-height: 40px;
    padding: 7px 16px;
    white-space: initial;
  }

  @media screen and (max-width: 1200px) {
    .pagina-de-entrada__container {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}

.pagina-de-entrada__container_mobile {
  order: 1;
  padding-top: 24px;
  padding-bottom: 24px;
  color: #fff;
  flex-direction: column;

  .pagina-de-entrada__conteudo-do-container {
    width: auto;
    max-width: 400px;
    z-index: 1;
  }

  @media screen and (min-width: 1199px) {
    display: none;
  }
}

.pagina-de-entrada__container_primario {
  background: none;

  &:before {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    .pagina-de-entrada__conteudo-do-container {
      padding-top: 0;
    }
  }
}

.pagina-de-entrada__container_secundario {
    .pagina-de-entrada__conteudo-do-container {
      max-width: 330px;
    }

    @media screen and (max-width: 1200px) {
      padding-top: 24px;
      padding-bottom: 24px;

      .pagina-de-entrada__conteudo-do-container {
        max-width: 400px;
      }
    }
}

.pagina-de-entrada__links-auxiliares {
  margin: 32px 8px 0;
  padding: 32px 0 24px;
  border-top: solid 2px $cor-cinza-20;
}

.pagina-de-entraga__conteudo-compartilhada {
    max-width: 500px;
    
    p {
        line-height: 1.5;
    }
}

.logos-do-sistema {
  max-width: 460px;
  width: 100%;
  height: auto;
}

.pagina-de-entrada__mensagem-erro {
  position: fixed;
  right: 16px;
  top: 16px;
  padding: 16px;
  max-width: 720px;
  padding-right: 32px;
  border: 2px solid $cor-erro;
  border-radius: 5px;
  background-color: lighten($cor-erro, 30%);
  color: $cor-erro;
  font-size: 14px;
  @include fonte-seminegrito;
  z-index: 2;
  width: calc(100% - 32px);
  cursor: pointer;

  &::after {
    content: "✖";
    position: absolute;
    top: 4px;
    right: 8px;
  }
}

.botao_largura-total {
  justify-content: center;
  width: 100%;
}

.pagina-de-entrada__logo-digix {
  position: absolute;
  right: 32px;
  bottom: -12px;
}