.cabecalho-do-formulario {
  .cabecalho-do-formulario__passo {
    color: var(--cor-secundaria);
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cabecalho-do-formulario__titulo {
    @include fonte-seminegrito;
    color: var(--cor-secundaria);
    text-align: center;
    font-size: 24px;
  }

  .cabecalho-do-formulario__descricao {
    text-align: center;
    padding: 14px;
    font-size: 16px;
  }
}