.tema-habix {

  .cabecalho-do-formulario__titulo,
  .cabecalho-do-formulario__passo {
    color: var(--cor-terciaria);
  }

  .cartao__icone,
  .cartao__titulo {
    color: var(--cor-terciaria);
  }

  .botao_cor-secundaria {
    background-color: var(--cor-terciaria);
  }

  .botao_cor-secundaria:not(.botao_contorno):not(.botao_sem-preenchimento) {
    box-shadow: 0 2px 2px hsla(var(--cor-terciaria-h), var(--cor-terciaria-s), var(--cor-terciaria-l), 0.3);
  }

  .botao_cor-secundaria:hover,
  .botao_cor-secundaria:focus {
    background-color: hsl(var(--cor-terciaria-h), var(--cor-terciaria-s), calc(var(--cor-terciaria-l) - 10%));
  }

  .botao_contorno.botao_cor-secundaria {
    color: var(--cor-terciaria);
    border: 2px solid hsla(var(--cor-terciaria-h), var(--cor-terciaria-s), var(--cor-terciaria-l), 0.5);
    background-color: transparent;
  }

  .botao_contorno.botao_cor-secundaria:hover,
  .botao_contorno.botao_cor-secundaria:focus {
    border: 2px solid var(--cor-terciaria);
  }

  .botao_sem-preenchimento.botao_cor-secundaria {
    color: var(--cor-terciaria);
    background-color: transparent;
  }

  .botao_sem-preenchimento.botao_cor-secundaria:hover,
  .botao_sem-preenchimento.botao_cor-secundaria:focus {
    background-color: hsla(var(--cor-terciaria-h), var(--cor-terciaria-s), var(--cor-terciaria-l), 0.1);
  }

  .botao_sem-preenchimento.botao_cor-secundaria:focus {
    border: 2px solid hsla(var(--cor-terciaria-h), var(--cor-terciaria-s), var(--cor-terciaria-l), 0.1);
  }

  .menu-de-inscricao__item_destaque {
    &:not(.menu-de-inscricao__item_desativado) {

      border: 2px solid hsla(var(--cor-terciaria-h), var(--cor-terciaria-s), var(--cor-terciaria-l), 0.5);

      &:hover,
      &:focus {
        border-color: var(--cor-terciaria);
      }
    }
  }

  .menu-de-inscricao__item {
    &:not(.menu-de-inscricao__item_desativado) {

      &:hover,
      &:focus {
        .menu-de-inscricao__numero-da-categoria {
          box-shadow: 0px 0px 0px 4px hsla(var(--cor-terciaria-h), var(--cor-terciaria-s), var(--cor-terciaria-l), 0.4);
        }
      }
    }
  }

  .menu-de-inscricao__titulo-do-conteudo {
    color: var(--cor-terciaria);
  }

  .menu-de-inscricao__numero-da-categoria {
    background-color: var(--cor-terciaria);
    box-shadow: 0px 0px 0px 4px hsla(var(--cor-terciaria-h), var(--cor-terciaria-s), var(--cor-terciaria-l), 0.2);
  }

  .fa_cor-secundaria {
    color: var(--cor-terciaria);
  }

  .texto_cor-secundaria {
    color: var(--cor-terciaria);
  }

  .botao.botao_medio.botao_largura-total.botao_secundaria {
    background-color: var(--cor-terciaria);
  }

  .menu-de-inscricao {
    top: 112px;
  }
  .lista-de-cadastro__titulo{
    color: var(--cor-terciaria);
  }
  .lista-de-cadastro__icone{
    color: var(--cor-terciaria);
  }
}