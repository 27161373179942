@import '~react-select/dist/react-select.css';
@import '~react-confirm-alert/src/react-confirm-alert.css';

.help {
    margin: 0 0 0.6em 0;
    font-size: 13px;
}

.is-danger {
    font-weight: 800;
}

.options-list > .column{
    padding: 0.75rem 0;
}

.is-borderless {
    border: none;
}

.react-confirm-alert-overlay {
    background: rgba($cor-preta, 0.5);

    .react-confirm-alert {
        width: 320px;
        @include borda-arredondada-padrao;
        padding: 20px;
        box-shadow: 0px 20px 75px rgba($cor-preta, .3);
        
        h3 {
            font-family: $fonte-padrao;
            font-size: 18px;
            @include fonte-seminegrito;
            line-height: 140%;
            margin-bottom: 24px;
            text-align: left;
            color: $cor-cinza-90;
        }
    }
}

.react-confirm-alert-button-group {
    button {
        font-family: $fonte-padrao;
        @include borda-arredondada-padrao;
        height: 40px;
        @include fonte-seminegrito;
        font-size: 16px;
        width: 100%;
        &:hover {
          transform: translate(0px, -1px);
        }

        &:first-child {
            color: $cor-branca;
            background: $cor-secundaria;
            border-color: $cor-secundaria;
            &:hover {
              border-color: darken($cor-secundaria, 10%);
              background: darken($cor-secundaria, 10%);
            }
        }

        &:last-child {
            color: $cor-branca;
            background: $cor-erro;
            &:hover {
              background: darken($cor-erro, 10%);
            }
        }
    }
}