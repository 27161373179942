.u-accordion-conteudo {
  max-height: 0;
  overflow: hidden;
}

.u-accordion-conteudo.expandido {
  max-height: 100%;
}

.u-borda-superior-cinza{
  border-color: #616161 !important;
}

.u-cabecalho-secao {
  cursor: pointer;
}

.botao_contorno{
  border-width: 1px !important;
}

.linha-vertical {
  border-left: 2px solid;
  margin-top: 4px;
 }

.linha-vertical.endereco{
  height: 35px;
}

.linha-vertical.historicoSeparacao{
  height: 100px;
}

.linha-vertical.historicoJuncao{
  height: 60px;
}

.colunaFlex {
  display: flex;
  padding: 0px 25px;
  margin: 0px -25px 6px;
}

.centro{
  display: flex;
  align-items: center;
  padding: 0px 15px;
  flex-direction: column;
}

.margemBotao {
  margin: 0px -16px;
}

.semBarraDeRolagem {
  overflow-x: hidden !important;
}

.fecharModal {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 20px;
  color: #888;
  cursor: pointer;
  font-size: 30px;
}