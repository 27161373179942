.aviso-de-documentos {
    background: url('../../imagens/governo-bg.png') $cor-cinza-20 no-repeat;
    background-size: cover;
    display: flex;
    height: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .container {
        @media screen and (min-width: 960px){
          max-width: 552px;
        }

        .titulo {
            @include fonte-seminegrito();
        }
    }

    .aviso-de-documentos__logos {
        max-height: 56px;
        width: 80%;
        margin-top: 24px;
    }

    .aviso-de-documentos__lista-documentos {
        list-style-type: disc;
        padding-left: 20px;
    }
}

.aviso-de-documentos__rodape {
    margin-top: 24px;
    margin-bottom: 0 !important;
}