.titulo-pergunta {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 30px;
}

.justificativa-separacao {
  margin-top: 30px;

}

.botoes-acoes {
  margin-top: 30px;
  display: flex;
  justify-content: end;
  margin-right: 30px;
  .botao:first-child {
    margin-right: 16px;
  }
}

.motivoDaSeparacao {
  margin-right: 10px;
}

.comparativoInscricao {
  display: flex;
  justify-content: center;

  .seta-direcao {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .inscricaoQuadroComparativo {
    border: 1px solid #9E9E9E;
    border-radius: 5px;
    padding: 10px;

    hr {
      border: 1px solid #9E9E9E;
      height: unset !important;
    }

    .item-titulo{
      display: flow;
      font-weight: 600;
      margin-top: 5px;
    }
    
    .item{
      display: flow;
      margin-top: 5px;
    }
  }
}