.selecao-de-modulos__item-de-menu:hover~.selecao-de-modulos__caixa {
    display: block;
}

.selecao-de-modulos__item-de-menu:focus~.selecao-de-modulos__caixa {
    display: block;
}

.selecao-de-modulos__caixa {
    display: none;
    position: absolute;
    top: 6px;
    left: -120px;
    margin: 24px;
    padding: 20px 0 0 0;
    z-index: 2000;
    border-radius: 5px;
    box-sizing: border-box;
    width: 606px;
}

.selecao-de-modulos__caixa:hover,
.selecao-de-modulos__caixa:focus {
    display: block;
}

.selecao-de-modulos__lista {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
}

.selecao-de-modulos__lista .selecao-de-modulos__cabecalho {
    display: flex;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    align-items: center;
    padding: 0 16px;
}

.selecao-de-modulos__lista .selecao-de-modulos__cabecalho:before {
    content: " ";
    position: absolute;
    top: 4px;
    left: 151px;
    height: 0;
    width: 0;
    border: solid transparent;
    border-color: transparent;
    border-width: 8px;
    margin-left: -8px;
    pointer-events: none;
    border-bottom-color: #fff;
}

.selecao-de-modulos__lista-de-itens {
    display: flex;
    flex-wrap: wrap;
    margin: 0 8px;
    cursor: pointer;
}

.selecao-de-modulos__lista-de-itens .selecao-de-modulos__item {
    width: 45%;
    padding: 10px;
    min-height: 72px;
    border: 1px solid #dddfe2;
    margin: 12px;
    border-radius: 4px;
    color: #000;
    font-size: 12px;
    display: flex;
}

.selecao-de-modulos__lista-de-itens .selecao-de-modulos__item:hover {
    border: 1px solid #bec2c9;
    box-shadow: -1px 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.selecao-de-modulos__lista-de-itens .selecao-de-modulos__item .selecao-de-modulos__icone-lista {
    margin: 10px 0;
    font-size: 21px;
    color: #f9ca36;
    min-width: 30px;
    text-align: center;
}

.selecao-de-modulos__lista-de-itens .selecao-de-modulos__item .selecao-de-modulos__nome-lista {
    font-size: 12px;
    margin: 0 13px;
}

.selecao-de-modulos__lista-de-itens .selecao-de-modulos__item .selecao-de-modulos__nome-lista span {
    font-weight: 700;
}