.carregando {
    color: #fff;
    position: fixed;
    box-sizing: border-box;
    left: -9999px;
    top: -9999px;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: 999999;
    
    &:after,
    &:before {
        box-sizing: border-box;
        display: none
    }
}

.carregando_ativo {
    background-color: rgba(0, 0, 0, .85);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    
    &:after,
    &:before {
        display: block !important;
    }
}


@keyframes rotation {
    0% {
        transform: rotate(0)
    }
    to {
        transform: rotate(359deg)
    }
}

.carregando:after {
    content: "";
    position: fixed;
    width: 48px;
    height: 48px;
    border: 8px solid #fff;
    border-left-color: transparent;
    border-radius: 50%;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    animation: rotation 1s linear infinite
}