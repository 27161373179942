.resumo__numero-inscricao, .resumo__informacoes-legais {
  background: $cor-cinza-10;
  padding: 16px;
  border-radius: 4px;
}

.resumo__numero-inscricao {
  margin-bottom: 24px;

  h3 {
    font-size: 20px;
  }

  .numero-inscricao__titulo {
    @include fonte-seminegrito;
    color: $cor-cinza-80;
  }
  
  .numero-inscricao__numero {
    font-weight: bold;
    color: var(--cor-terciaria);
  }
}

.resumo__informacoes-legais {
  color: $cor-cinza-80;
  
  p, ol {
    font-size: 15px;
    list-style-position: inside;
  }

  .titulo {
    @include fonte-seminegrito;
  }
}

.resumo__bloco {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto 1fr;
  border-bottom: 2px solid $cor-cinza-20;
  margin-bottom: 24px;
  padding-bottom: 8px;
  font-size: 16px;

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}


.bloco-resumo__titulo {
  grid-column: 1 / 6;
  grid-row: 1 / 2;
  font-size: 16px;
  .titulo {
    @include fonte-seminegrito;
  }
}

.bloco-resumo__dados {
  color: $cor-cinza-70;
  grid-row: span 1;
  font-size: 14px;
  text-transform: uppercase;
}

.bloco-resumo__dados-pessoais {
  grid-column: span 3;
  
  @media screen and (min-width: 600px) {
    margin-right: 8px;
    padding-right: 8px;
    border-right: 2px solid $cor-cinza-20;
  }
}

.bloco-resumo__dados-de-contato {
  grid-column: span 3;
}

.bloco-resumo_largura-total {
  grid-column: span 6;
}

.bloco-resumo__link {
  grid-column: 6 / 7;
  grid-row: 1 / 2;
  text-align: right;
  vertical-align: middle;
}

@media screen and (max-width: 600px){
  .resumo__numero-inscricao {
    h3 {
      font-size: 18px;
    }
    
    p {
      font-size: 14px;
    }
  }

  .bloco-resumo__titulo {
    grid-column: 1 / 7;
  }

  .bloco-resumo_metade {
    grid-column: span 6;
  }
  
  .bloco-resumo__link {
    grid-column: 1 / 7;
    grid-row: 4 / 5;
    text-align: left;
    padding: 8px 0;
  }
}