.pagina-pretendente__container {
    @include coluna-flex(center);
    .button {
        width: 100%;
    }

    .title {
        color: $cor-secundaria;
        margin-bottom: $espacamento-padrao;
    }
}