.barra-navegacao__titulo {
  font-weight: 700;
}

@media (min-width: 767px) {
  .barra-navegacao__conteudo-principal {
      flex-grow: 1;
      display: flex;
      align-items: center;
  }
}

.menu-administrativo__lista {
  @media screen and (max-width: 600px) {
    width: 280px !important;
  }
}
