.formulario__input[type="password"] {
  text-transform: none;
}

.formulario__input_nome-social {
  .grade__coluna {
    padding: 0;
  }
}

.formulario__checkbox-container input:checked[type="checkbox"], 
.formulario__checkbox-container input:checked[type="radio"], 
.formulario__radio-container input:checked[type="checkbox"], 
.formulario__radio-container input:checked[type="radio"],
.formulario__checkbox-marcador:checked, 
.formulario__radio-marcador:checked {
  &:focus {
    box-shadow: 0 0 0 2px rgba($cor-info, 0.4);
  }
}