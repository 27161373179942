.annotate{
	font-style: italic;
	color: $cor-secundaria;
}

.hidden {
	display: none;
}

.combobox-wrapper {
	position: relative;
	font-size: 16px;
}

.combobox-wrapper {
	input {
		padding-right: 48px;
	}
}

.listbox, .grid {
	background: $cor-branca;
	border: 1px solid $cor-cinza-20;
	list-style: none;
	margin: 0;
	position: absolute;
	top: 40px;
	z-index: 1;
	max-height: 26vh;
	overflow-y: auto;
	width: 100%;
	border-radius: 5px;
	box-shadow: 0 4px 16px 0 rgba($cor-preta, .1);
	@media screen and (max-width: 1024px) {
		max-height: 50vh;
	}
}

.listbox .result {
	cursor: pointer;
	margin: 0;
	padding: 10px 8px;
}

.listbox .result:hover, .grid .result-row:hover {
	background: rgba($cor-secundaria, .2);
}

.listbox .focused, .grid .focused {
	background: rgba($cor-secundaria, .2);
}

.grid .focused-cell {
	outline-style: dotted;
	outline-color: $cor-primaria;
}


.combobox-dropdown {
	display: flex;
	position: absolute;
	cursor: pointer;
	right: 2px;
	top: 2px;
	padding: 0 12px;
	height: calc(100% - 4px);
	outline: 0;
	border-radius: 0 3px 3px 0;
	border-left: 2px solid $cor-cinza-20;
	&:hover {
		background: rgba($cor-preta, .1);
	}
	img {
		height: 8px;
		margin: auto;
	}
	&.disabled {
		background: $cor-cinza-20;
		pointer-events: none;
	}
}

[aria-expanded="true"] {
	 .combobox-dropdown {
		 img {
			 transform: rotate(180deg)
		 }
	 }
}

.grid .result-row {
	padding: 2px;
	cursor: default;
	margin: 0;
}

.grid .result-cell {
	display: inline-block;
	cursor: default;
	margin: 0;
	padding: 0 5px;
}

.grid .result-cell:last-child {
	float: right;
	font-size: 12px;
	font-weight: 200;
	color: $cor-cinza-50;
	line-height: 24px;
}