.aviso-de-inconsistencia {
    display: none;
    flex-direction: column;
    justify-content: center;
    background: lighten($cor-atencao, 45%);
    border-radius: 4px;
    border: 2px solid $cor-atencao;
    padding: 16px;
    margin: 0 auto;
    margin-bottom: 16px;
    transition: .2s ease;
    will-change: border, transform;

    &:focus {
        outline: none;
        transform: translateY(-2px);
        border-color: darken($cor-atencao, 10%);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
    }
}

.aviso-de-inconsistencia__icone {
    color: $cor-aviso-icone;
    font-size: 16px;
    text-align: center;
}

.aviso-de-inconsistencia__titulo {
    color: $cor-cinza-80;
    font-size: 18px;
    font-weight: 700;
    svg {
        margin-right: 8px;
        color: $cor-atencao;
    }
}

.aviso-de-inconsistencia__paragrafo {
    font-size: 16px;
    color: $cor-cinza-80;
}

.aviso-de-inconsistencia__lista {
    margin-top: $espacamento-duplo;
    font-size: 16px;
    list-style-type: disc;
    list-style-position: inside;
    color: #363636;
    font-weight: 500;
}

.aviso-de-inconsistencia_visivel {
    display: flex;
}