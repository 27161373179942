.formulario__input-react-select {
  .Select-control {
    border: 2px solid $cor-cinza-30;
    border-radius: 8px;
    height: 42px;
    transition: border-color 0.2s ease;
    
    &:hover {
      border-color: rgba($cor-info, .4);
    }
  }

  .is-focused,
  .is-focused:not(.is-open) {
    .Select-control {
      border-color: $cor-info;
      box-shadow: none !important;
    }
  } 

  .is-disabled {
    .Select-control {
      cursor: not-allowed;
      border-color: #e0e0e0!important;
      background-color: #eee!important;
    }
  }

  .Select-placeholder, .Select--single > .Select-control .Select-value {
    line-height: 38px;
    color: $cor-cinza-70;
  }
  
  .Select-placeholder{
    line-height: 38px;
  }
}

.formulario__input-react-select_erro {
  .Select-control {
    background: rgba($cor-erro, .05);
    border-color: rgba($cor-erro, .5);

    &:hover {
      border-color: rgba($cor-erro, .5);
    }
  }
}
 