.botao_contorno {
  &.botao_erro,
  &.botao_cor-perigo {
    &:hover,
    &:focus {
      background: rgba($cor-erro, 0.07);
    }
  }

  &.botao_secundaria {
    &:hover,
    &:focus {
      background: hsla(var(--cor-secundaria-h), var(--cor-secundaria-s), var(--cor-secundaria-l), 0.1);
    }
  }

  &.botao_cor-cinza {
    &:hover,
    &:focus {
      background: $cor-cinza-20;
    }
  }
}

.botao_invertido {
  &.botao_primaria {
    &:focus {
      border-color: $cor-cinza-20;   
    }
  }
}

.u-botao-arredondado {
  border-radius: 30px;;
}